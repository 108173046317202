@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Merriweather+Sans:wght@300;400;600;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.headerContainer{
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), hsla(0, 0%, 100%, 0));
}

.demo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.demo > div{
  padding-right: 40px;
}

.lang {
  margin: 0 auto;
}

.button_lang {
  cursor: pointer;
  display: inline-block;
  padding: 0.25em 0em;
  border-radius: 10px;
  width: 5em;
  transition: 0.25s ease;
  color: #fff;
}

.button_lang:hover {
  background: #b5d6b2;
  transition: 0.25s ease;
}

.current_lang {
  background: #b5d6b2;
}

.translation {
  text-align: center;
  margin: 0.5em;
}


.titleHeader{
  font: 24px 'Audiowide', cursive;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dailyMovie{
  position: relative;
  height: 100%;
  background: #000;
}

.dailyMovieBackground{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;
  padding: 20px;
  padding-top: 80px;
}

.dailyMovieContainer{
  max-width: 600px;
  margin-top: 120px;
  height: 100vh;
}
.dailyMovieTitle{
  background: #fff;
  font-size: 50px;
  letter-spacing: 2px;
  font: 50px 'Merriweather Sans';
}
.dailyMovieDescription{
  padding-left: 45px;
  box-shadow: 13px 13px 18px rgb(20 19 19 / 75%);
  background: rgba(0,0,0,0.7);
  color: #fff;
  text-align: left;
  font:20px/40px 'Merriweather Sans'
}
.movieBlock{
  font:600 30px 'Merriweather Sans';
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  position: relative;
  max-width: fit-content;
  padding: 0 10px 0 45px;
}

.movieBlock:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background: yellow;
}

.dailyTrailerContainer{
  margin-top: 30px;
}

.playButton{  
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  border-radius: 500px;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 600;
  text-align: center;
  padding: 16px 14px 18px;
  color: #000;
  box-shadow: inset 0 0 0 2px #fff;
  background-color: #fff;
  height: 48px;
  margin: 20px 0;
}

@media (min-width:601px){
  .playButton{
    margin: 0 20px;
  }
}

.playButton:hover{
  color: #fff;
  background-color: #ff0000;
  box-shadow: inset 0 0 0 2px #ff0000;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
